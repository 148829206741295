
import { defineComponent } from 'vue'
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
export default defineComponent({
  name: 'Default',
  components: {
    Navbar,
    Footer,
  },
})
