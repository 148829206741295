<template>
  <nav :class="{ shadow: isScroll }">
    <div class="inner">
      <router-link custom to="/" v-slot="{ navigate }">
        <div id="logo" @click="navigate">
          <svg>
            <use href="#resaizer_logo" />
          </svg>
        </div>
      </router-link>

      <router-link :to="{ name: isDemo ? 'Home' : 'Demo' }" class="btn btn-sm">
        {{ isDemo ? '簡介' : '試用' }}
      </router-link>
    </div>
  </nav>
</template>
<script>
import { defineComponent, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import '@/static/svg/resaizer_logo.svg'
import '@/static/svg/c0de_logo.svg'

export default defineComponent({
  setup() {
    const { currentRoute } = useRouter()
    const isDemo = ref(currentRoute.value.name === 'Demo')
    const isScroll = ref(false)
    const addNavShadow = () => {
      document.documentElement.scrollTop === 0
        ? (isScroll.value = false)
        : (isScroll.value = true)
    }

    onMounted(() => {
      window.addEventListener('scroll', addNavShadow)
    })

    watch(currentRoute, () => {
      isDemo.value = currentRoute.value.name === 'Demo'
    })
    return { isScroll, isDemo }
  },
})
</script>

<style lang="scss">
.shadow {
  transition: 0.3s;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

nav {
  transition: 0.3s;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
  padding: 0 2rem;
  width: 100%;
  height: 6rem;
  background-color: $color-white;

  .inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  #logo {
    height: 4rem;
    padding: 0.5rem 0;
    svg {
      cursor: pointer;
      width: 200px;
    }
  }
}

@media (max-width: 768px) {
  .navbar {
    padding: 0 1rem;
  }
}

@media (max-width: 600px) {
  nav {
    height: 4rem;
    #logo {
      height: 3rem;
      svg {
        width: 150px;
      }
    }
  }
}
</style>
