<template>
  <footer>
    <div class="logo">
      <svg>
        <use href="#resaizer_logo" />
      </svg>
    </div>

    <div class="company">
      <p>Powered by</p>
      <a href="https://codezero.tw/" target="_blank">
        <svg>
          <use href="#c0de_logo" />
        </svg>
      </a>
    </div>
  </footer>
</template>

<style lang="scss">
footer {
  padding: 2rem 0;
  color: $color-gray;
  background-color: $color-gray-light;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .logo {
    height: 3rem;
    margin-bottom: 0.5rem;
    svg {
      width: 200px;
    }
  }
  .company {
    display: flex;
    align-items: center;
    p {
      font-size: 1rem;
      line-height: 1.5rem;
    }
    svg {
      width: 90px;
      vertical-align: text-bottom;
      padding-left: 0.5rem;
      height: 1rem;
      fill: $color-gray;
    }
  }
}

@media (max-width: 768px) {
  footer .logo {
    height: 2rem;
    padding: 0;
    margin-bottom: 0rem;
  }
}
</style>
